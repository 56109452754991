<template lang='pug'>
.pricing.row
  .col-md-6
    label(for="companysize")
      | {{ $t("wählen_sie") }}
    select.custom-select(id="companysize" v-model="selected")
      option(v-for="count in emplyeeCount" :value="count.value")
        | {{ count.count }}
  .col-md-6
    price-overview(:price="getSelected()")

</template>

<script>
import PriceOverview from "./PriceOverview"

export default {
  components: { PriceOverview },
  props: ['pricing'],
  data() {
    return {
      selected: 0,
    }
  },
  computed: {
    emplyeeCount() {
      return this.pricing.map((price, index) => {
        return {
          count: `${price.Stufe.von} - ${price.Stufe.bis}`,
          value: index
        }
      });
    }
  },
  watch: {

  },
  methods: {
    getSelected() {
      return this.pricing[this.selected]
    }

  },
}
</script>

<style scoped lang="scss">

</style>

<i18n lang='yaml'>
de:
  wählen_sie: Wählen Sie Ihre Mitarbeiteranzahl
en:
  wählen_sie: Choose your number of employees
</i18n>
