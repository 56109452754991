/* eslint-disable */
import $ from 'jquery'
if (!('jQuery' in window)) {
  window.jQuery = $
  window.$ = $
}
import turbolinksAddon from 'utils/turbolinks-jquery'
turbolinksAddon($)

var Turbolinks = require("turbolinks")
window.Turbolinks = Turbolinks
if ('disableTurbolinks' in window && window.disableTurbolinks) {
} else {
  Turbolinks.start()
}

// import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import Rails from 'rails-ujs/lib/assets/compiled/rails-ujs.js';


if (!('Rails' in window)) {
  window.Rails = Rails
  Rails.start();
}
if (!('BMS' in window)) {
  window.BMS = {}
}

const Stickyfill = require('stickyfilljs')

var myDefaultWhiteList = $.fn.tooltip.Constructor.Default.whiteList

myDefaultWhiteList.a = []
myDefaultWhiteList.a = ['data-toggle', 'href', 'data-target', 'data-remote', 'data-method']
$(document).ready(() => {
  Stickyfill.add($('.sticky'));
})

export default $
