import "core-js/stable"
import "regenerator-runtime/runtime"
import $ from 'recruiter/jquery_with_bs'
import axios from 'utils/axios'
import 'intersection-observer';
import scrollama from 'scrollama';
import Vue from 'vue';
import PricingCalculator from 'public/components/PricingCalculator'
import addDraggable from 'utils/draggable'
import 'utils/cookies'
import 'controllers'
addDraggable($)

import i18n from 'utils/i18n'

window.jQuery = $
window.$ = $

import 'public/style.scss';

const applyVue = (tagName, component) => {
  $(tagName).each((_, el) => {
    new Vue({
      el,
      render: h => h(component, { props: $(el).data() }),
      i18n
    })
  })
}

$(document).on('turbolinks:load', function(){
  "use strict";
  $("#ebms-signup").hide()
  var window_width    = $(window).width(),
  window_height      = window.innerHeight,
  header_height      = $(".default-header").height(),
  header_height_static = $(".site-header.static").outerHeight(),
  fitscreen        = window_height - header_height;

  $(".fullscreen").css("height", window_height)
  $(".fitscreen").css("height", fitscreen);

  const elementExists = document.getElementsByClassName("scroll-area")
  if (elementExists.length > 0) {
    const scroller = scrollama();
    scroller.setup({
      step: document.querySelector('.scroll-area'),
      progress: true,
      offset: 0.1,
      threshold: 2
    })
    .onStepEnter(() => {
      $('#ebms-signup')
        .fadeIn(1000)
    })
    .onStepExit(()=> {
      $('#ebms-signup')
        .fadeOut()
    })
    window.addEventListener('resize', scroller.resize);
  }
})

$(document).ready(() => {
  applyVue('pricing-calculator', PricingCalculator)
})
