<template lang='pug'>
.overview
  .card
    .card-body
      .d-flex.flex-row.justify-content-between.mt-5
        .pl-0
          | {{ $t("zahlung") }}
        .price.lead.font-weight-bold
          | {{formatNumber(price.Netto)}}
      hr.mt-3
      .d-flex.flex-row.justify-content-center
        a(type="button" class="genric-btn primary circle arrow" href="https://login.empfehlungsbund.de/registrieren/" target="_blank")
          | {{ $t("registrieren") }}
</template>

<script>

export default {
  props: {
    price: { type: Object, required: true },
  },

  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number)
    },

  },
}
</script>

<style scoped lang="scss">

</style>

<i18n lang='yaml'>
de:
  registrieren: Jetzt Registrieren
  zahlung: Jährliche Zahlung
en:
  registrieren: Register now
  zahlung: Annual payment
</i18n>
