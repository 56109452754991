import { Controller } from "@hotwired/stimulus"
import { iframeResizer } from "iframe-resizer"

export default class extends Controller {
  connect() {
    const iframe = this.element
    iframe.setAttribute("visibility", "hidden")
    setTimeout(() => {
      const resizer = iframeResizer(
        {
          checkOrigin: false,
          log: true,
          messageCallback: function (messageData) {
            console.log("messageCallback", { messageData })
          },
        },
        iframe
      )
      iframe.setAttribute("visibility", "visible")
    }, 500)
    // iframe.attr("scrolling", "yes").css("overflow", "normal")
    iframe.setAttribute("scrolling", "yes")
    iframe.style.overflow = "normal"
    // check if window still loading, if so, force resize trigger after
  }
}
