// https://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
/* eslint-disable func-names */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable no-mixed-operators */
/* eslint-disable camelcase */
export default function ($) {
  $.fn.draggable = function (opt) {
    opt = $.extend({ handle: "", cursor: "move" }, opt);

    var $parent = this;
    if (opt.handle === "") {
      var $el = this;
    } else {
      var $el = this.find(opt.handle);
    }

    return $el.css('cursor', opt.cursor).on("mousedown", function (e) {
      if (opt.handle === "") {
        var $drag = $(this).addClass('draggable');
      } else {
        var $drag = $(this).addClass('active-handle').parent()
        $parent.addClass('draggable');
      }
      const z_idx = $drag.css('z-index')
      const pz_idx = $parent.css('z-index')
      const drg_h = $drag.outerHeight()
      const drg_w = $drag.outerWidth()
      const pos_y = $drag.offset().top + drg_h - e.pageY
      const pos_x = $drag.offset().left + drg_w - e.pageX
      $drag.css('z-index', 1000).parents().on("mousemove", (e) => {
        $('.draggable').offset({
          top: e.pageY + pos_y - drg_h,
          left: e.pageX + pos_x - drg_w
        }).on("mouseup", function () {
          $(this).removeClass('draggable').css('z-index', z_idx);
          $parent.removeClass('draggable').css('z-index', pz_idx);
        });
      });
      e.preventDefault(); // disable selection
    }).on("mouseup", function () {
      if (opt.handle === "") {
        $(this).removeClass('draggable');
        $parent.removeClass('draggable')
      } else {
        $(this).removeClass('active-handle').parent().removeClass('draggable');
        $parent.removeClass('draggable')
      }
    });
  }
}
