import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "icon"]

  toggle() {
    this.iconTarget.classList.toggle("mdi-eye")
    this.iconTarget.classList.toggle("mdi-eye-off")
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text"
    } else {
      this.passwordTarget.type = "password"
    }
  }
}
